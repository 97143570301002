import Swiper from 'swiper'

import { Navigation } from 'swiper/modules'

import 'swiper/css'

import { defineComponent } from '~/scripts/utils/alpine'
import { setRootCSSVariable } from '~/scripts/utils/css'

export default defineComponent(() => ({
  swiperInstance: undefined as Swiper | undefined,
  init() {
    this.swiperInstance = new Swiper(this.$root, {
      slidesPerView: 1.2,
      spaceBetween: 16,
      grabCursor: true,
      navigation: {
        nextEl: this.$refs.next,
        prevEl: this.$refs.prev,
      },
      breakpoints: {
        1280: {
          slidesPerView: 2.1,
        },
        1633: {
          slidesPerView: 3.5,
        },
      },
      modules: [Navigation],
      on: {
        slideChange: () => {
          if (!this.swiperInstance) {
            return
          }

          setRootCSSVariable(
            'hero-slider-progress',
            this.swiperInstance.progress.toString(),
          )
        },
      },
    }) as Swiper
  },
}))
