import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '../composables/useElementSize'

gsap.registerPlugin(ScrollTrigger)

export default defineComponent(() => {
  return {
    init() {
      this.initLogoAnimation()
    },
    initLogoAnimation() {
      const logo = this.$refs.logo

      const { height } = useElementSize(logo)

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: 'body',
          scrub: true,
          start: '0',
          end: height * 2,
        },
      })
      timeline.from(logo, {
        y: height,
        ease: 'none',
      })
      timeline.to(logo, {
        scale: 0.8,
        ease: 'none',
      })
    },
  }
})
