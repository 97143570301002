import rosetta from 'rosetta'

const messages = {
  en: {
    clipboard: {
      success: 'Copied to clipboard',
      error: 'An error has occurred',
      notAvailable: 'This feature is not available in this browser',
    },
    contest: {
      already_participated: 'You have already participated in this contest.',
      contest_over: 'The contest is over.',
    },
  },
  fr: {
    clipboard: {
      success: 'Copié dans le presse-papier',
      error: 'Une erreur est survenue',
      notAvailable:
        "Cette fonctionnalité n'est pas disponible dans ce navigateur",
    },
    contest: {
      already_participated: 'Vous avez déjà participé à ce concours.',
      contest_over: 'Le concours est terminé.',
    },
  },
  de: {
    clipboard: {
      success: 'In die Zwischenablage kopiert',
      error: 'Ein Fehler ist aufgetreten',
      notAvailable: 'Diese Funktion ist in diesem Browser nicht verfügbar.',
    },
    contest: {
      already_participated:
        'Sie haben bereits an diesem Wettbewerb teilgenommen.',
      contest_over: 'Der Wettbewerb ist beendet.',
    },
  },
}

const defaultLocale = 'en'

export function useI18n(
  locale: string | (() => string) = () => document.documentElement.lang,
) {
  const locales = Object.keys(messages)
  const i18n = rosetta(messages)
  const currentLocale = typeof locale === 'function' ? locale() : locale
  const matchingLocale = locales.find(
    (locale) => locale === currentLocale.split('-')[0],
  )
  i18n.locale(matchingLocale || defaultLocale)
  return {
    ...i18n,
    t: (
      key: string | (string | number)[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parameters?: any[] | Record<string, any> | undefined,
      lang?: string | undefined,
    ) => {
      const value = i18n.t(key, parameters, lang)
      if (value === '') return key
      return value
    },
  }
}
