import { useI18n } from '~/scripts/composables/useI18n'
import { useToast } from '~/scripts/composables/useToast'

export function useClipboard(toastStore = window.$store?.toast) {
  return async (subject: string) => {
    const toast = useToast(toastStore)
    const { t } = useI18n()

    if (!navigator.clipboard) {
      toast.info({
        message: t('clipboard.notAvailable') as string,
      })
      return
    }

    try {
      await navigator.clipboard.writeText(subject)

      toast.success({
        message: t('clipboard.success') as string,
      })
    } catch (error) {
      toast.error({
        message: t('clipboard.error') as string,
        error: error as Error,
      })
    }
  }
}
