import { defineComponent } from '~/scripts/utils/alpine'

import { useElementSize } from '~/scripts/composables/useElementSize'
import { setRootCSSVariable } from '~/scripts/utils/css'

export default defineComponent(() => ({
  init() {
    useElementSize(this.$root, ({ height }) =>
      setRootCSSVariable('hero-cover-height', `${height}px`),
    )
  },
}))
