import { defineComponent } from '~/scripts/utils/alpine'

interface CountdownOptions {
  date: string
}

export default defineComponent((options: CountdownOptions) => {
  return {
    date: new Date(options.date).getTime(),
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    timeLeft: Number.POSITIVE_INFINITY,
    get ended() {
      return this.timeLeft <= 0
    },
    init() {
      const render = () => {
        this.update()
        if (!this.ended) {
          requestAnimationFrame(render)
        }
      }

      render()
    },
    update() {
      // Extract time left in days, hours and minutes
      const now = Date.now()
      const distance = this.date - now
      this.timeLeft = distance

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    },
    format(value: number) {
      return value.toString().padStart(2, '0')
    },
  }
})
