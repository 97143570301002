import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'

import { defineComponent } from '~/scripts/utils/alpine'

const DEFAULT_ACTIVE_ESTATE = 'satigny'

export default defineComponent(() => ({
  swiperInstance: undefined as Swiper | undefined,
  activeEstate: DEFAULT_ACTIVE_ESTATE as string | undefined,
  init() {
    this.swiperInstance = new Swiper(this.$refs.swiper, {
      slidesPerView: 1,
      spaceBetween: 16,
      grabCursor: true,
      loop: true,
      autoHeight: true,
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        bulletClass:
          'inline-block mx-1.5 w-3 h-3 bg-white rounded-full transition duration-300',
        bulletActiveClass: '!bg-strawberry-600',
        clickable: true,
      },
      on: {
        slideChange: (swiper: Swiper) => {
          const slide = swiper.slides[swiper.activeIndex]

          if (!slide) {
            return
          }
          this.activeEstate = slide.dataset.estate
        },
      },
    }) as Swiper
  },
  selectEstate(estate?: string) {
    if (!estate) return
    if (this.activeEstate === estate) return
    if (!this.swiperInstance) return

    const index = this.swiperInstance?.slides.findIndex(
      (s) => s.dataset.estate === estate,
    )
    if (index < 0) return

    this.swiperInstance.slideTo(index)
  },
}))
