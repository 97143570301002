import Alpine from 'alpinejs'
import { gsap } from 'gsap'

import { defineComponent } from '~/scripts/utils/alpine'

import { useI18n } from '~/scripts/composables/useI18n'

interface ContestOptions {
  share: {
    title: string
    message: string
  }
}

const { t } = useI18n()
const lang = (document.documentElement.lang || 'en-US').split('-')[0]

export default defineComponent((options: ContestOptions) => ({
  totalSteps: 2,
  currentStep: 0,
  panelOpened: false,
  isAvailable: true,
  isLoading: false,
  hasAlreadyParticipated: Alpine.$persist(false),
  // Prize drawn
  prize: undefined as { id: number; name: string } | undefined,
  // Form
  formErrorMessage: undefined as string | undefined,
  formData: {
    email: undefined as string | undefined,
    firstname: undefined as string | undefined,
    lastname: undefined as string | undefined,
  },
  async init() {
    this.$store.ui.lockScrollBy('contest')

    if (this.hasAlreadyParticipated) {
      this.formErrorMessage = t('contest.already_participated') as string
    } else {
      const isAvailable = await this.checkAvailability()
      if (isAvailable) {
        // Focus first input
        const firstField = this.$root.querySelector<HTMLInputElement>(
          'form input[type="text"]:first-child',
        )
        if (firstField) {
          firstField.focus()
        }
      } else {
        // Contest is over
        this.formErrorMessage = t('contest.contest_over') as string
        this.isAvailable = false
      }
    }
  },
  go(step: number) {
    this.currentStep = step
  },
  async open() {
    // Reset to first step
    this.currentStep = 0
    await this.$nextTick()

    this.panelOpened = true
  },
  close() {
    this.panelOpened = false
  },
  runWheel() {
    const { prizeTitle, prizeDescription } = this.$refs
    const frame = this.$refs.wheel.querySelector('.frame')

    const timeline = gsap.timeline({ delay: 0.5 })
    timeline
      .to(frame, {
        duration: 4,
        rotate: gsap.utils.random(2010, 2040),
        ease: 'power2.inOut',
        transformOrigin: '50% 50%',
      })
      .fromTo(
        prizeTitle.children,
        {
          autoAlpha: 0,
          scale: 0,
        },
        {
          scale: 1,
          autoAlpha: 1,
          duration: 1,
          stagger: 0.1,
          ease: 'elastic.out(1.5,0.75)',
        },
      )
      .fromTo(
        prizeDescription.children,
        {
          autoAlpha: 0,
          y: 10,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: 'power3.out',
        },
        '-=0.7',
      )
  },
  async draw(event: SubmitEvent) {
    this.isLoading = true

    if (!(await this.checkAvailability())) {
      this.formErrorMessage = t('contest.contest_over') as string
      this.isAvailable = false
      this.isLoading = false
      return
    }

    const response = await fetch(`/wp-json/contest/prize-draw?lang=${lang}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        Object.fromEntries(new FormData(event.target as HTMLFormElement)),
      ),
    })

    const data = await response.json()

    if (!response.ok) {
      // Message can of different types
      if (typeof data.message === 'string') {
        this.formErrorMessage = data.message
      } else if (Array.isArray(data.message)) {
        this.formErrorMessage = data.message.join('\n')
      } else if (typeof data.message === 'object') {
        this.formErrorMessage = Object.values(data.message).join('\n')
      }

      this.isLoading = false
      return
    }

    this.prize = {
      id: data.id,
      name: data.name,
    }

    this.isLoading = false
    this.hasAlreadyParticipated = true

    this.go(1)
    this.runWheel()
  },
  async checkAvailability(): Promise<boolean> {
    const response = await fetch(`/wp-json/contest/available?lang=${lang}`)
    const data = await response.json()

    if (!data.success) {
      this.formErrorMessage = data.message
      return false
    }

    return true
  },
  get canShare() {
    return !!navigator.share
  },
  share() {
    if (!navigator.share) {
      return
    }

    try {
      navigator.share({
        title: options.share.title,
        text: options.share.message,
        url: window.location.href,
      })
    } catch {}
  },
}))
