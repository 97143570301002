import { useElementSize } from '~/scripts/composables/useElementSize'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

export interface MarqueeOptions {
  scrollAmount: number
}

/**
 * Marquee
 * @param {Object} options
 * @param {number} [options.scrollAmount=180] - The amount of pixels to scroll per second
 */
export default defineComponent((options?: MarqueeOptions) => ({
  isPlaying: false,
  duration: 5, // seconds
  init() {
    const { item } = this.$refs
    if (!item) return

    const { scrollAmount } = { scrollAmount: 180, ...options }

    const scrollTrigger = ScrollTrigger.create({
      trigger: this.$root,
      start: 'top bottom',
      end: 'bottom top',
      onToggle: ({ isActive }) => {
        this.isPlaying = isActive
      },
    })

    useElementSize(item, ({ width }) => {
      this.duration = width / scrollAmount
      scrollTrigger.refresh()
    })
  },
}))
